module directives {
    export module applicationcore {
        

        export class menuSelectDirective implements ng.IDirective {

            templateUrl = `templates/modules/applicationcore/menuSelectView.html`;
            scope = {
                loadData: "&"
            };

            controller = "menuSelectCtrl";
            controllerAs = "menuSelectCtrl";

            constructor() {

            }

            link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery) => {
                
            }

            static factory(): ng.IDirectiveFactory {
                const directive = () => new menuSelectDirective();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").directive("gtsMenuSelect", menuSelectDirective.factory());
    }
}